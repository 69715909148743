<style lang="scss">
    /* Import Font Awesome Icons Set */
    $fa-font-path: '~@fortawesome/fontawesome-free/webfonts/';
    @import '~@fortawesome/fontawesome-free/scss/fontawesome.scss';
    @import '~@fortawesome/fontawesome-free/scss/regular.scss';
    @import '~@fortawesome/fontawesome-free/scss/solid.scss';

    /* Import Bootstrap Vue Styles */
    /*  doing this elsewhere  scss  @import 'bootstrap-vue/dist/bootstrap-vue.css'; */

    // Import Main styles for this application
    @import '@/assets/scss/style';
</style>

<template lang="html">
    <div>
        <b-modal ref="message" :title="message.title" ok-only ok-title="Close" :header-bg-variant="message.variant" :ok-variant="message.variant" @hidden="msg_hidden">
            <p>{{message.text}}</p>
        </b-modal>

        <router-view></router-view>
    </div>
</template>

<script lang="js">
export default {
    name: 'app',
    data() {
        return {
            message: {
                visible: false,
                variant: "danger",
                title: "",
                text: ""
            },
            msg_queue: []
        };
    },
    created() {
        // Disable default drag and drop behavior for missed drag of files
        document.body.addEventListener( 'dragover', function( event ) {
            event.preventDefault();
            event.stopPropagation();
        }, false );
        document.body.addEventListener( 'drop', function( event ) {
            event.preventDefault();
            event.stopPropagation();
        }, false );

        // Error dialog for API errors. Single close button
        this.$emitter.on( 'api-error',  e => {
            console.error( e );

            // NOTE: This will move to api.js when that is implemented
            if( e?.response?.status == 401 ) {
                this.$router.push({ name: "Login" });
                return;
            }

            let message = e?.response?.data?.message;
            if( !message )
                message = e?.message;
            if( !message ) {
                message = "An unhandled API error has occurred.";
                if( undefined !== e?.response?.config?.url )
                    message += " URL: " + e?.response?.config?.url;
                if( undefined !== e?.response?.status )
                    message += " Code: " + e?.response?.status;
            }
            this.msg_show( 'danger', {
                title: e.title || "APi Error",
                text: message
            }, false );
        });
        // Error dialog. Single close button
        this.$emitter.on( 'modal-error', m => {
            this.msg_show( 'danger', m );
        });
        // Informational dialog. Single close button
        this.$emitter.on( 'modal-info', m => {
            this.msg_show( 'primary', m );
        });
        // Notification pop-up
        this.$emitter.on( 'notification', message => {
            const m = this.$createElement( 'p', { class: [ 'text-center' ] }, [
                this.$createElement( 'strong', message )
            ]);
            this.$bvToast.toast( [ m ], {
                title: '',
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true,
                variant: 'primary',
                noCloseButton: true
            });
        });
    },
    methods: {
        msg_show( variant, msg, queue = true ) {
            if( this.message.visible ) {
                if( queue )
                    this.msg_queue.push({ variant, msg });
                return;
            }
            this.message.visible = true;
            this.message.variant = variant;
            this.message.title = msg.title || "";
            this.message.text = msg.text || "No text provided";
            this.message.ok_text = msg.ok || "Close";
            this.message.close = msg.ok || null;
            this.$refs.message.show();
        },
        msg_close( e ) {
            if( typeof this.message.close == 'function' )
            this.message.close();
            this.$nextTick(() => {
                this.$refs.message.hide();
            })
        },
        msg_hidden() {
            this.message.visible = false;
            const msg = this.msg_queue.shift();
            if( msg )
                this.msg_show( msg.variant, msg.msg );
        }
    }
}
</script>
