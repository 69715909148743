import Axios from 'axios';

export class Auth {
    constructor( emitter ) {
        this.$emitter = emitter;
        this.appdata = null;
    }

    get authenticated() {
        return this.appdata?.authenticated;
    }

    get eula() {
        return this.appdata?.eula;
    }

    get email() {
        return this.appdata?.email;
    }

    get is_user() {
        return this.appdata?.is_user;
    }

    get is_manager() {
        return this.appdata?.is_manager;
    }

    get is_admin() {
        return this.appdata?.is_admin;
    }

    // Get session application data if the user is already logged in on the server
    async init() {
        if( null === this.appdata )
            await this.refresh();
    }

    async refresh() {
        // TODO: What error handling should happen here????
        const response = await Axios.get( "/appdata" );
        this.appdata = response.data?.appdata;
    }

    // Send sign-up request
    async signup( user ) {
        try {
            await Axios.post( "/user/signup", user );
            return true;
        }
        catch( err ) {
            this.$emitter.emit( 'modal-error', {
                title: "Sign-Up",
                text: "There was an error processing the sign-up request. Please contact sales@flexdm.net for assistance."
            });
            return false;
        }
    }

    // Issue login API request. Set app data on success
    async login( credentials ) {
        try {
            const response = await Axios.post( "/auth/login", credentials );
            this.appdata = response.data.appdata;
        }
        catch( e ) {
            if( e?.response?.status == 401 ) {
                this.$emitter.emit( 'modal-error', {
                    title: "Login",
                    text: e.response.data?.message || "Login unsuccessful"
                });
            }
            else {
                this.$emitter.emit( 'modal-error', {
                    title: "Login",
                    text: "There was a system error processing the login request. Please try again later."
                });
            }
        }
    }

    // Issue logout API request
    // Clear appdata
    async logout() {
        const response = await Axios.post( "/auth/logout" ).catch( e => {} );
        this.appdata = null;
    }

    // Session timed out
    timeout() {
        this.appdata = null;
    }

    // Send a request to reset the users password
    async forgotPassword( email ) {
        try {
            await Axios.post( "/user/forgot", { email })
            this.$emitter.emit( 'modal-info', {
                title: "Password Reset",
                text: "A password reset request has been sent. Please check you e-mail."
            });
            return true;
        }
        catch( e ) {
            this.$emitter.emit( 'modal-error', {
                title: "Password Reset",
                text: "There was an error requesting a password reset. Please contact sales@flexdm.net for assistance."
            });
            return false;
        }
    }

    // Accept EULA. On decline user is logged out
    async acceptEula( accept ) {
        try {
            const response = await Axios.post( "/auth/eula", { accept });
            this.appdata = response.data.appdata;
        }
        catch( e ) {
            if( e.isAxiosError() )
                this.$emitter.emit( 'api-error', e );
            else {
                this.$emitter.emit( 'modal-error', {
                    title: "Accept EULA",
                    text: "There was an error processing the request."
                });
            }
        }
    }

    // Check if a password is good based on password strength requirements
    // Returns true on a secure password
    // This routine should match the one in web/www/auth.js
    is_secure_password( password )
    {
        if( typeof password !== 'string' )      // Not a string:  BAD
            return false;
        if( password.length < 8 )               // Too short:     BAD
            return false;
        if( password.length > 15 )              // Really long:   GOOD
            return true;
    
        // Count the number of character classes this password has
        const char_class_regex = [ /[a-z]/, /[A-Z]/, /[0-9]/, /\W/ ];  // \W -- Non word chars
        let char_class_count = 0;
        for( const r of char_class_regex ) {
            if( r.test( password ))
            char_class_count++;
        }
    
        // A password with atleast 3 classes of characters is ok
        if( char_class_count >= 3 )
            return true;
    
        // More than 12 characters and two classes of characters is ok
        if( password.length > 11 && char_class_count == 2 )
            return true;
    
        // Too short or too few character classes
        return false;
    }
}
